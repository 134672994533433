import axios, { AxiosRequestConfig } from "axios";

export class HttpService {
  constructor(readonly spotifyToken: string) {}

  getHttp = async (config: AxiosRequestConfig) => {
    const baseConfig: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${this.spotifyToken}`,
      },
    };
    return axios({...config, ...baseConfig});
  }
}