import { Link } from "react-router-dom";
import { useAuth } from "./Auth";
import { useLocation } from 'react-router-dom'

export const Header = () => {
  const { signOut } = useAuth();
  const location = useLocation();
  return (
    <div className="header">
      <Link
        className="logo"
        to={{
          pathname: "/",
        }}
      >
        Spoti<span className="white">friends</span>
      </Link>

      <div className="header-right">
        <Link
          className={location.pathname === "/" ? "active" : ""}
          to={{
            pathname: "/",
          }}
        >
          Your Tops
        </Link>
        <Link
          to={{
            pathname: "/friends",
          }}
          className={location.pathname === "/friends" ? "active" : ""}
        >
          Your friends
        </Link>
        <Link
          to={{
            pathname: "/follow-me",
          }}
          className={location.pathname === "/follow-me" ? "active" : ""}
        >
          Invite Friends
        </Link>
        <a onClick={signOut} href="/">
          Logout
        </a>
      </div>
    </div>
  );
};
