import { User } from "@supabase/supabase-js";
import { supabase } from "../supabaseClient";
import { SpotifyUser } from "./SpotifyService";

export interface UserProfile {
  id:  string,
  username: string,
  avatar_url: string,
  website: string,
  spotify_id: string,
  updated_at: Date,
  token: string,
}

export const getFriendsFromUser = async (userId: string): Promise<UserProfile[]> => {
  let { error, data } = await supabase
  .from("friends")
  .select(`
    user_id,
    profiles!friends_follower_id_fkey (
      id,
      username,
      avatar_url,
      website
    )
  `)
  .eq("user_id", userId)
  .then(result => result)

  if (error) {
    console.error(error);
    throw error;
  }
  return data ? data.map(d => d.profiles) : []
};

export const getUserById = async (id: string): Promise<UserProfile | undefined> => {
  const { data, error } = await supabase
    .from("profiles")
    .select("*")
    .eq("id", id)
    .then((result) => result);
  if (error) {
    throw error;
  }
  return data.length === 1 ? data[0] : undefined;
};

export const createUser = async (spotifyUser: SpotifyUser, user: User) => {
  const newUser = {
    id: user.id,
    username: spotifyUser.display_name,
    avatar_url: spotifyUser.images.length > 0 ? spotifyUser.images[0].url : null,
    website: spotifyUser.external_urls.spotify,
    spotify_id: spotifyUser.id,
    updated_at: new Date(),
  };

  let { error } = await supabase.from("profiles").upsert(newUser, {
    returning: "minimal",
  });

  if (error) {
    throw error;
  }
};
