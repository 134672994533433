import { SanitizedTopTracks } from "../services/SpotifyService";

interface TracksListProps {
  tracks: SanitizedTopTracks[];
  limit: number
}

export const TracksList = (props: TracksListProps) => {
  const { tracks, limit } = props;
  const slicedTracks = tracks.slice(0, limit)
  return (
    <>
      {slicedTracks.map((track, index) => (
        <div key={index} className="tracks-card">
          <img
            src={track.thumbnail}
            alt={track.name}
            className="tracks-thumbnail"
          />
          <p className="tracks-artist-name">{track.artists}</p>
          <p className="tracks-name">{track.name}</p>
          <p className="tracks-year">{track.year}</p>
          <audio className="tracks-preview" controls src={track.preview} />
        </div>
      ))}
    </>
  );
};
