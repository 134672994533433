import { useState } from "react";
import { useAuth } from "./Auth";
import { Loading } from "./components/Loading";

export const FollowMe = () => {
  const { user } = useAuth();
  const [writtenToClipboard, setIsWrittenToClipboard] = useState(false);

  if (!user) {
    return <Loading />;
  }

  const copyToClipboard = () => {
    const link = window.location.href + "/" + user.id;
    navigator.clipboard.writeText(link).then(() => {
      setIsWrittenToClipboard(true);
      setTimeout(() => {
        setIsWrittenToClipboard(false);
      }, 3000);
    });
  };

  return (
    <div className="friends-container">
      <div className="friends-title">
        Invite your <span className="green">friends</span> !
      </div>
      <div className="friends-description">
        The more the merrier ! Copy the link below and send it to your friend !
      </div>
      <button onClick={copyToClipboard}>Copy</button>
      {writtenToClipboard && <p>Successfully written to clipboard !</p>}
    </div>
  );
};
