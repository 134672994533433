import { useEffect, useState } from "react";
import { BsSpotify } from "react-icons/bs";
import { useLocation } from "react-router-dom";
import { useAuth } from "./Auth";
import { postSlackHook } from "./services/slackService";

export const Login = () => {
  const [email, setEmail] = useState("");
  const [success, setSucces] = useState("");
  const [error, setError] = useState("");
  const { signIn } = useAuth();

  const location = useLocation();

  const updateEmail = (ev: any) => {
    const val = ev.target.value;
    setEmail(val);
  };

  const urlSearch = new URLSearchParams(location.search);

  useEffect(() => {
    if (urlSearch.has("error") && urlSearch.get("error") === "server_error") {
      setError(
        "You need to sign-up first ! TRNDLZ needs to activate your account"
      );
    }
  }, []);

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendHook = () => {
    if (validateEmail(email)) {
      setError("");
      postSlackHook(`@trndlz: New request from ${email}`).then(() =>
        setSucces("Sign-up request is succesfully made !")
      );
    } else {
      setError("You need to provide a valid email!");
    }
  };

  return (
    <div className="auth-container">
      <div className="friends-title" style={{ marginBottom: "50px" }}>
        Welcome to Spoti<span className="green">friends</span>
      </div>
      <p className="description">
        New here ? Create an account ! Please give us your email address linked
        to your spotify account !
      </p>
      <div className="sign-up-box">
        <div className="sign-up-form">
          <input
            placeholder="britney@spears.com"
            onChange={updateEmail}
            value={email}
            className="sign-up-input"
          />
          <button onClick={sendHook} className="sign-up-input">
            Sign-up
          </button>
        </div>
        {error && <div className="sign-up-alert">{error}</div>}
        {success && <div className="sign-up-success">{success}</div>}
      </div>
      <p className="description">Already have an account ? Log in here !</p>
      <button className="button primary" onClick={signIn}>
        <BsSpotify /> Login with Spotify
      </button>
    </div>
  );
};
