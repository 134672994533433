import axios from "axios";

const hookUrl = process.env.REACT_APP_HOOK_URL;

export const postSlackHook = (input: string) => {
    const url = hookUrl
    const data = JSON.stringify({text: input})

    return axios.post(url!, data, {
      withCredentials: false,
      transformRequest: [(data, headers) => {
        delete (headers!.post as any)['Content-Type']
        return data
      }]
    })
};
