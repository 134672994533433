import { User } from "@supabase/supabase-js";
import { supabase } from "../supabaseClient";
import { SanitizedTopArtist, SanitizedTopTracks, SpotifyTopTimeRange } from "./SpotifyService";

export const upsertTracks = async (
  top: SanitizedTopTracks[],
  user: User,
  time_range: SpotifyTopTimeRange
) => {
  const supTracks = {
    id: user.id + "_" + time_range,
    tracks: JSON.stringify(top),
    updated_at: new Date(),
    time_range: time_range,
    user_id: user.id,
  };

  let { error } = await supabase.from("tracks").upsert(supTracks, {
    returning: "minimal",
  });

  if (error) {
    throw error;
  }
};

export const getTracksByTimerange = async (timeRange: SpotifyTopTimeRange, userId: string): Promise<SanitizedTopTracks[]>  => {
  const selection = await supabase.from("tracks").select("tracks").eq("user_id", userId).eq("time_range", timeRange)
  if (!selection.body) throw Error
  return JSON.parse(selection.body.map((x) => x.tracks)[0])
} 

export const getArtistsByTimerange = async (timeRange: SpotifyTopTimeRange, userId: string): Promise<SanitizedTopArtist[]>  => {
  const selection = await supabase.from("artists").select("artists").eq("user_id", userId).eq("time_range", timeRange)
  if (!selection.body) throw Error
  return JSON.parse(selection.body.map((x) => x.artists)[0])
} 

export const upsertArtists = async (
  top: SanitizedTopArtist[],
  user: User,
  time_range: SpotifyTopTimeRange
) => {
  const supArtists = {
    id: user.id + "_" + time_range,
    artists: JSON.stringify(top),
    updated_at: new Date(),
    time_range: time_range,
    user_id: user.id,
  };

  let { error } = await supabase.from("artists").upsert(supArtists, {
    returning: "minimal",
  });

  if (error) {
    throw error;
  }
};

