import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAuth } from "./Auth";
import { Loading } from "./components/Loading";
import { Login } from "./Login";
import { upsertFriendship } from "./services/friendsService";
import { getUserById } from "./services/profileService";

export const InitiateFollow = () => {
  const { session, user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<undefined | string>(undefined)
  let { id } = useParams<{ id: string }>();

  useEffect(() => {
    if (user && session) {
      getUserById(id)
        .then((supaUser) => {
          if (!supaUser) {
            setError("This User does not exist !");
          } else if (supaUser.id === user!.id) {
            setError("You can't follow yourself");
          } else {
            upsertFriendship(user.id, id);
          }
        })
        .catch((err) => console.log("wrong id", err))
        .finally(() => setLoading(false));
    }
  }, [id, user, session]);

  if (!session || !user) {
    return <Login />;
  }

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div className="friends-container">
        <div className="friends-title">
          Follow a new <span className="green">friend</span> !
        </div>
        {error ? (
          <div>Could not add friend: {error}</div>
        ) : (
          <div><b>Friendship successfully added !</b></div>
        )}
      </div>
    );
  }
};
