import { User } from "@supabase/supabase-js";
import { createUser, getUserById } from "../services/profileService";
import {
  sanitizeArtists,
  sanitizeTracks,
  SpotifyService,
  SpotifyTopTimeRange,
} from "../services/SpotifyService";
import { upsertArtists, upsertTracks } from "../services/topService";

const fetchAndUpsertTracksData = async (
  user: User,
  spotifyService: SpotifyService
): Promise<void> => {
  const timeRanges: SpotifyTopTimeRange[] = [
    "long_term",
    "medium_term",
    "short_term",
  ];
  const allTimePromises = timeRanges.map((timeRange) =>
    spotifyService.getTopTracks(timeRange, 50)
  );
  return Promise.all(allTimePromises)
    .then((tracksResponses) => {
      tracksResponses.forEach((tracksResponse, index) => {
        const sanitizedTracks = sanitizeTracks(tracksResponse.data.items);
        upsertTracks(sanitizedTracks, user, timeRanges[index]);
      });
    })
    .catch((err) => console.log(err));
};

const fetchAndUpsertArtistsData = async (
  user: User,
  spotifyService: SpotifyService
): Promise<void> => {
  const timeRanges: SpotifyTopTimeRange[] = [
    "long_term",
    "medium_term",
    "short_term",
  ];
  const allTimePromises = timeRanges.map((timeRange) =>
    spotifyService.getTopArtists(timeRange, 50)
  );
  return Promise.all(allTimePromises)
    .then((artistsResponses) => {
      artistsResponses.forEach((artistsResponse, index) => {
        const sanitizedArtists = sanitizeArtists(artistsResponse.data.items);
        upsertArtists(sanitizedArtists, user, timeRanges[index]);
      });
    })
    .catch((err) => console.log(err));
};

const upsertUser = async (
  user: User,
  spotifyService: SpotifyService
): Promise<void> => {
  return getUserById(user.id)
    .then((supaUser) => {
      if (!supaUser) {
        spotifyService
          .getMe()
          .then((res) => createUser(res.data, user))
          .catch((err) => console.log(err));
      }
    })
    .catch((err) => console.log(err));
};

export const fetchAllSpotifyData = async (
  user: User,
  spotifyService: SpotifyService
) => {
  return upsertUser(user, spotifyService).then(() => {
    return Promise.all([
      fetchAndUpsertArtistsData(user, spotifyService),
      fetchAndUpsertTracksData(user, spotifyService),
    ]);
  });
};
