import { supabase } from "../supabaseClient";

export interface Friendship {
  user_id: string;
  follower_id: string;
}

const insertFriendship = async (
  userId: string,
  followerId: string
): Promise<void> => {
  let { error } = await supabase.from<Friendship>("friends").insert(
    {
      user_id: userId,
      follower_id: followerId,
    },
    {
      returning: "minimal",
    }
  );

  if (error) {
    throw error;
  }
};

export const upsertFriendship = async (
  userId: string,
  followerId: string
): Promise<void> => {
  return supabase
    .from<Friendship>("friends")
    .select("*", { count: 'exact'})
    .eq("follower_id", followerId)
    .eq("user_id", userId)
    .then((value) => {
      console.log("::::", value)
      if (value.error) {
        throw value.error;
      } else if (value.count && value.count > 0) {
        return ;
      } else {
        return insertFriendship(userId, followerId);
      }
    });
};
