import { Session, User } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "./supabaseClient";

interface AppContextInterface {
  signIn: () => any;
  signOut: () => any;
  user?: User;
  session?: Session
}

const defaultContext: AppContextInterface = {
  signIn: () => undefined,
  signOut: () => undefined,
}

const AuthContext = createContext(defaultContext);

export function AuthProvider({ children }: any) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | undefined>(undefined);

  useEffect(() => {
    const session = supabase.auth.session();
    setUser(session?.user ?? undefined);
    setLoading(false);

    const { data: listener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log("Session change", event, session);
        setUser(session?.user ?? undefined);
        setLoading(false);
      }
    );

    return () => {
      listener?.unsubscribe();
    };
  }, []);


  const supabaseSignin = async () => {
     await supabase.auth.signIn(
        { provider: "spotify" },
        { scopes: "user-top-read user-read-email" }
      ).then((a) => console.log("1", a))
      .catch((e) => console.log("2", e))
    //   console.log("HHH", signIn);
    //   if (signIn.error) {
    //     console.log(signIn);
    //     throw signIn.error
    //   };
    // } catch (error: any) {
    //   console.log("HELLO");
    //   console.log(error.error_description || error.message);
    // }
  }

  const value: AppContextInterface= {
    signIn: supabaseSignin,
    signOut: () => supabase.auth.signOut(),
    session: supabase.auth.session() || undefined,
    user,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}

export function useAuth(): AppContextInterface {
  return useContext(AuthContext)
}
