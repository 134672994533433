import { useEffect, useState } from "react";
import { useAuth } from "./Auth";
import { Loading } from "./components/Loading";
import { getFriendsFromUser, UserProfile } from "./services/profileService";
import { GoLinkExternal } from "react-icons/go";
import { Link } from "react-router-dom";

export const FriendsList = () => {
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [friends, setFriends] = useState<UserProfile[]>([]);

  useEffect(() => {
    if (user) {
      getFriendsFromUser(user.id)
        .then((friends) => setFriends(friends))
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }
  }, [user]);

  if (!user) {
    return <Loading />;
  }

  const size = 240;

  return (
    <div className="friends-container">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="friends-title">
            Your Spoti<span className="green">friends</span>
          </div>
          <div className="friends-description">
            This is your current friend list. Want to invite more ? Click on
            "invite friends"
          </div>
          <div className="friends-map">
            {friends.map((friend, i) => {
              return (
                <div key={i} className="friends-box">
                  <Link to={`/friend/${friend.id}`}>
                    <img
                      key={i}
                      alt={friend.avatar_url}
                      src={
                        friend.avatar_url ??
                        `https://place-hold.it/${size}x${size}?fontsize=24&text=${friend.username}`
                      }
                      className="friends-avatar"
                    />
                  </Link>
                  <p className="friends">
                    {friend.username} <GoLinkExternal />
                  </p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};
