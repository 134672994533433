import { SanitizedTopArtist } from "../services/SpotifyService";

interface ArtistsListProps {
  artists: SanitizedTopArtist[];
  limit: number
}

export const ArtistsList = (props: ArtistsListProps) => {
  const { artists, limit } = props;
  const sliced = artists.slice(0, limit)
  return (
    <>
      {sliced.map((artist, index) => (
        <div key={index} className="artist-card">
          <img
            src={artist.thumbnail}
            alt={artist.name}
            className="artist-thumbnail"
          />
          <p className="artist-name">{artist.name}</p>
          <p className="artist-genre">{artist.genres.split(",").map((genre, i) => <span key={i} className="artist-genre-ind">{genre}</span>)}</p>
        </div>
      ))}
    </>
  );
};
