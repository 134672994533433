import { useState, useEffect } from "react";
import {
  SanitizedTopArtist,
  SanitizedTopTracks,
  SpotifyTopTimeRange,
  SpotifyTopType,
} from "./services/SpotifyService";
import {
  getArtistsByTimerange,
  getTracksByTimerange,
} from "./services/topService";
import { TracksList } from "./components/TracksList";
import { ArtistsList } from "./components/ArtistsList";
import { Loading } from "./components/Loading";
import { useParams } from "react-router-dom";

interface SpotifyTopsProps {
  userId?: string;
}

const SpotifyTops = (props: SpotifyTopsProps) => {
  const [topArtists, setTopArtists] = useState<SanitizedTopArtist[]>([]);
  const [topTracks, setTopTracks] = useState<SanitizedTopTracks[]>([]);
  const [mode, setMode] = useState<SpotifyTopType>("tracks");
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(true);
  const [timerange, setTimerange] = useState<SpotifyTopTimeRange>("short_term");

  const { userId } = props;
  const { id } = useParams<{ id: string }>();
  const finalUserId = userId || id;
  const prefix = id ? "Your Friend Spotify" : "Your Spotify"

  useEffect(() => {
    getTracksByTimerange(timerange, finalUserId).then((tracks) => {
      setTopTracks(tracks);
      setLoading(false);
    });
  }, [timerange, finalUserId]);

  useEffect(() => {
    getArtistsByTimerange(timerange, finalUserId).then((artists) => {
      setTopArtists(artists);
      setLoading(false);
    });
  }, [timerange, finalUserId]);

  return (
    <div className="tops-container">
      <div className="friends-title">
      {prefix} <span className="green">Tops</span>
      </div>
      <div className="friends-description">
        You can see here the most heard artists or tracks through different time frames
      </div>
      <div className="mode-filters">
        <button
          className={`mode-filters-buttons ${
            mode === "artists" && "mode-active"
          }`}
          onClick={() => setMode("artists")}
        >
          Top Artists
        </button>
        <button
          className={`mode-filters-buttons ${
            mode === "tracks" && "mode-active"
          }`}
          onClick={() => setMode("tracks")}
        >
          Top Tracks
        </button>
      </div>
      <div className="time-filters">
        <button
          className={`time-filters-buttons ${
            timerange === "short_term" && "tracks-active"
          }`}
          onClick={() => setTimerange("short_term")}
        >
          Last month
        </button>
        <button
          className={`time-filters-buttons ${
            timerange === "medium_term" && "tracks-active"
          }`}
          onClick={() => setTimerange("medium_term")}
        >
          Last 6 months
        </button>
        <button
          className={`time-filters-buttons ${
            timerange === "long_term" && "tracks-active"
          }`}
          onClick={() => setTimerange("long_term")}
        >
          Last years
        </button>
      </div>
      <div className="limit-filters">
        <button
          className={`limit-filters-buttons ${limit === 10 && "limit-active"}`}
          onClick={() => setLimit(10)}
        >
          10
        </button>
        <button
          className={`limit-filters-buttons ${limit === 25 && "limit-active"}`}
          onClick={() => setLimit(25)}
        >
          25
        </button>
        <button
          className={`limit-filters-buttons ${limit === 50 && "limit-active"}`}
          onClick={() => setLimit(50)}
        >
          50
        </button>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="artist-container">
          {topArtists && mode === "artists" && (
            <ArtistsList artists={topArtists} limit={limit} />
          )}
          {topTracks && mode === "tracks" && (
            <TracksList tracks={topTracks} limit={limit} />
          )}
        </div>
      )}
    </div>
  );
};

export default SpotifyTops;
