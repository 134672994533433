import { AxiosResponse } from "axios";
import { HttpService } from "./httpService";

const SPOTIFY_BASE_URL = "https://api.spotify.com/v1/";

interface SpotifyUserImage {
  height: number;
  url: string;
  width: number;
}

interface SpotifyExternalUrl {
  spotify: string;
}

interface SpotifyFollowers {
  href: null;
  total: number;
}

export interface SpotifyUser {
  display_name: string;
  external_urls: SpotifyExternalUrl;
  followers: SpotifyFollowers;
  href: string;
  id: string;
  images: SpotifyUserImage[];
  type: "user";
  uri: string;
}

export type SpotifyCurrentUser = SpotifyUser & {
  product: string;
};

export interface SanitizedTopArtist {
  name: string;
  thumbnail: string;
  genres: string;
}

export type SpotifyTopArtist = SpotifyArtist & {
  followers: SpotifyFollowers;
  genres: string[];
  images: SpotifyUserImage[];
  popularity: number;
};

export interface SpotifyArtist {
  external_urls: SpotifyExternalUrl;
  href: string;
  id: string;
  name: string;
  type: SpotifyTopType;
  uri: string;
}

export interface SanitizedTopTracks {
  artists: string;
  thumbnail: string;
  name: string;
  year: string;
  preview: string;
}

export interface SpotifyTopTracks {
  album: {
    album_type: string;
    artists: SpotifyArtist[];
    available_markets: string[];
    external_urls: SpotifyExternalUrl;
    href: string;
    id: string;
    images: SpotifyUserImage[];
    name: string;
    release_date: string;
    release_date_precision: string;
    total_tracks: number;
    type: string;
    uri: string;
  };
  artists: SpotifyArtist[];
  available_markets: string[];
  disc_number: number;
  duration_ms: number;
  explicit: false;
  external_ids: {
    isrc: string;
  };
  external_urls: {
    spotify: string;
  };
  href: string;
  id: string;
  is_local: boolean;
  name: string;
  popularity: number;
  preview_url: string;
  track_number: number;
  type: string;
  uri: string;
}

interface SpotifyTop<T> {
  href: string;
  items: T[] | T[];
  limit: number;
  next: string | null;
  offset: number;
  previous: string | null;
  total: number;
}

export type SpotifyTopType = "artists" | "tracks";

export type SpotifyTopTimeRange = "short_term" | "medium_term" | "long_term";

export class SpotifyService {
  constructor(readonly spotifyToken: string) {}

  private httpService = new HttpService(this.spotifyToken);

  getUserById(id: string): Promise<AxiosResponse<SpotifyUser>> {
    return this.httpService.getHttp({
      method: "get",
      url: SPOTIFY_BASE_URL + "users/" + id,
    });
  }

  getMe(): Promise<AxiosResponse<SpotifyCurrentUser>> {
    return this.httpService.getHttp({
      method: "get",
      url: SPOTIFY_BASE_URL + "me",
    });
  }

  getTopTracks(
    time_range: SpotifyTopTimeRange,
    limit?: number,
    offset?: number
  ): Promise<AxiosResponse<SpotifyTop<SpotifyTopTracks>>> {
    return this.httpService.getHttp({
      method: "get",
      url: SPOTIFY_BASE_URL + "me/top/tracks",
      params: {
        limit,
        offset,
        time_range,
      },
    });
  }

  getTopArtists(
    time_range: SpotifyTopTimeRange,
    limit?: number,
    offset?: number
  ): Promise<AxiosResponse<SpotifyTop<SpotifyTopArtist>>> {
    return this.httpService.getHttp({
      method: "get",
      url: SPOTIFY_BASE_URL + "me/top/artists",
      params: {
        limit,
        offset,
        time_range,
      },
    });
  }
}

export const sanitizeTracks = (
  tracks: SpotifyTopTracks[]
): SanitizedTopTracks[] => {
  return tracks.map((track) => {
    return {
      artists: track.artists.map((a) => a.name).join(", "),
      thumbnail: track.album.images[1].url,
      name: track.name,
      year: track.album.release_date.slice(0, 4),
      preview: track.preview_url,
    };
  });
};

export const sanitizeArtists = (
  artists: SpotifyTopArtist[]
): SanitizedTopArtist[] => {
  return artists.map((artist) => {
    return {
      name: artist.name,
      thumbnail: artist.images[2].url,
      genres: artist.genres.join(","),
    };
  });
};
