import { AuthProvider } from "./Auth";
import "./index.css";
import { MainPage } from "./MainPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Login } from "./Login";
import { Header } from "./Header";
import { FollowMe } from "./FollowMe";
import { InitiateFollow } from "./InitiateFollow";
import { FriendsList } from "./FriendsList";
import SpotifyTops from "./SpotifyTops";

export const App = () => {
  return (
    <Router>
      <AuthProvider>
        <section>
          <Header />
          <div className="container">
            <Switch>
              <Route path="/login" component={Login} />
              <Route path="/follow-me/:id" component={InitiateFollow} />
              <Route path="/follow-me" component={FollowMe} />
              <Route path="/friends" component={FriendsList} />
              <Route path="/friend/:id" component={SpotifyTops} />
              <Route path="/" component={MainPage} />
            </Switch>
          </div>
        </section>
      </AuthProvider>
    </Router>
  );
};
