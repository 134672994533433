import { useEffect, useState } from "react";
import SpotifyTops from "./SpotifyTops";
import { useAuth } from "./Auth";
import { fetchAllSpotifyData } from "./helper/dataFetcher";
import { Login } from "./Login";
import { SpotifyService } from "./services/SpotifyService";
import { Loading } from "./components/Loading";

export const MainPage = () => {
  const { session, user } = useAuth();
  const [loading, setLoading] = useState(true);
  
  useEffect(() => {
    if (session?.provider_token && user) {
      const spotifyService = new SpotifyService(session.provider_token)
      fetchAllSpotifyData(user, spotifyService).then(() => setLoading(false))
    }
  }, [session?.provider_token, user])

  if (!session?.provider_token || !user) {
    return <Login />
  }

  if (loading) {
    return <Loading />
  } else {
    return <SpotifyTops userId={user.id}/>
  } ;
};
